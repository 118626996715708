import currency from 'currency.js'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import axios from '../../axios'
import HighchartsTemplate from './HighchartTemplate'
const axiosInstance = axios.create()
axiosInstance.defaults.headers.common['X-Api-Key'] = 'b2jqI6b9nDMBUKfPBBYkhW8tI8Q5DnqpX0biA2X6'

export default function YtdSales(props) {
    const [ytd_sales, setSales] = useState({})
    useEffect(() => {
        axiosInstance.get('/sales/graphs/1/ytd_sales/v2/json').then((payload) => {
            setSales(payload.data)
        })
    }, [])

    function convertPercentage(val) {
        if (val < 0) {
            return (
                <span className='ytd_ticker text-red'>
                    {val}% <span className='far fa-arrow-down' />
                </span>
            )
        } else if (val > 0) {
            return (
                <span className='ytd_ticker text-green'>
                    {val}% <span className='far fa-arrow-up' />
                </span>
            )
        } else if (val == 0) {
            return <span className='ytd_ticker'> {val}% --</span>
        }
    }

    // console.log('ytd sales', ytd_sales);

    if (_.isEmpty(ytd_sales)) {
        return <span className='far fa-spinner fa-spin fa-5x' />
    }

    const { graph, years_compare } = ytd_sales

    let display = []

    _.forEach(years_compare, (row, key) => {
        let percentage = convertPercentage(row.percentage)

        display.push(
            <div
                style={{
                    flex: 1,
                    textAlign: 'center',
                    margin: '0 5px',
                }}
                key={key}
            >
                <h4>
                    {key.toUpperCase()}
                    <br />
                    {percentage}
                </h4>
            </div>
        )
    })

    let series = []
    let categories = []
    const colors = { Collections: '#f7941d', UCC: '#00aeef', Liens: '#008440', Notices: '#00a651' }
    _.forEach(graph, (row, key) => {
        let data = []
        categories = []
        row.map((year) => {
            data.push(parseInt(year.total))
            categories.push(dayjs(year.month + '-' + year.year, 'M-YYYY').format('MMM-YY'))
        })
        series.push({ name: key, data: data, color: colors[key] })
    })

    const config = {
        title: {
            text: 'Year to Date Sales',
        },
        chart: {
            type: 'line',
        },
        yAxis: {
            title: {
                text: 'Sales',
            },
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
        },
        xAxis: {
            categories,
            tickmarkPlacement: 'on',
            title: {
                text: 'Month',
            },
        },
        tooltip: {
            formatter: function () {
                return this.series.name + '<br/>' + this.x + ': ' + currency(this.y).format(true)
            },
        },
        plotOptions: {
            series: {
                animation: {
                    duration: 4000,
                },
            },
        },
        credits: false,
        series,
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                        },
                    },
                },
            ],
        },
    }

    return (
        <div>
            <div
                style={{
                    minHeight: 5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                }}
            >
                {display.length > 0 ? display : <span className='far fa-5x fa-spin fa-spinner' />}
            </div>
            <br />
            <div className='row'>
                <div className='col-12'>
                    <HighchartsTemplate id='ytd_sales' config={config} />
                </div>
            </div>
        </div>
    )
}
