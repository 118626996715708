// import React from 'react'
// import axios from '../../axios'
// import LoadingSpinner from '../../utils/LoadingSpinner'
// import HighchartsTemplate from './HighchartTemplate'

// import useSWR from 'swr'

// const fetcher = (url) => axios.get(url).then((res) => res.data)

const CedReport = (props) => {
    return null
    // const { data, error } = useSWR('/cli/ced/1/importCounts/json', fetcher, {
    //     refreshInterval: 300000,
    // })
    // const months = [
    //     'Jan',
    //     'Feb',
    //     'Mar',
    //     'Apr',
    //     'Mar',
    //     'Jun',
    //     'Jul',
    //     'Aug',
    //     'Sep',
    //     'Oct',
    //     'Nov',
    //     'Dec',
    // ]

    // function getOptions(series) {
    //     return {
    //         chart: {
    //             type: 'column',
    //         },
    //         legend: {
    //             enabled: true,
    //         },
    //         xAxis: {
    //             title: { text: 'Month' },
    //             categories: months,
    //         },
    //         yAxis: {
    //             title: { text: '# of Imported Projects' },
    //         },
    //         title: {
    //             text: 'CED Imported Projects (ESTIMATES)',
    //         },
    //         plotOptions: {
    //             column: {
    //                 stacking: 'normal',
    //                 dataLabels: {
    //                     enabled: true,
    //                 },
    //             },
    //         },
    //         credits: {
    //             enabled: false,
    //         },
    //         series: series,
    //     }
    // }

    // if (error)
    //     return (
    //         <div className='row'>
    //             <h2>Failed to load data, please contact Rob Ranallo if issue persists</h2>
    //         </div>
    //     )
    // if (!data)
    //     return (
    //         <div className='row'>
    //             <h2>
    //                 <LoadingSpinner /> Loading...
    //             </h2>
    //         </div>
    //     )

    // return <HighchartsTemplate id={props.id} config={getOptions(data)} />
}

export default CedReport
