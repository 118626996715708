import { useContext, useEffect, useState } from 'react'
import axios from '../../axios'
import { PortalContext } from '../../GlobalState'

export default function UccCurrentOpen(props) {
    const [isLoading, setLoading] = useState(false)
    const [current_open, setOpen] = useState({})
    const { employee } = useContext(PortalContext)

    useEffect(() => {
        setLoading(true)
        axios.get(`/portal/ucc/1/stage_totals/${employee.EmpID}/json`).then((stage_totals) => {
            setLoading(false)
            setOpen(stage_totals.data)
        })
    }, [])

    // console.log('ucc current open',current_open);

    if (isLoading) {
        return <span className='far fa-spinner fa-spin' />
    }

    let display = []
    if (current_open.length > 0) {
        current_open.map((row) => {
            display.push(
                <div key={row.stage_id} className='ucc_widget_item'>
                    <strong
                        className={row.priority > 0 ? 'text-red' : ''}
                        style={{ fontSize: '32px', display: 'block' }}
                    >
                        {row.total}
                    </strong>
                    {row.stage_name.toUpperCase()}
                </div>
            )
        })
    }

    if (!isLoading && current_open.result == false) {
        display = <h4>You have nothing currently open</h4>
    }

    return (
        <div>
            <div className='row'>
                <div className='col-8'>
                    <h3 style={{ margin: 0 }}>Currently Open</h3>
                </div>
                <div className='col-4'>
                    <span className='float-end'>
                        <a href='/ucc/' className='btn btn-secondary btn-sm'>
                            <span className='far fa-sign-in' /> Go To UFile
                        </a>
                    </span>
                </div>
            </div>
            <div className='ucc_widget_container'>{display}</div>
        </div>
    )
}
